import defaultState from './defaultState'

export default {
  preloader(state, isLoading) {
    state.isLoading = isLoading
  },
  preloaderExport(state, isExportLoading) {
    state.isExportLoading = isExportLoading
  },
  setTags(state, payload) {
    state.tags.push(payload)
  },
  setTag(state, payload) {
    state.tags[0].push(payload)
  },
  setTagItem(state, data) {
    state.tagsUsers[data.type] = [...(state.tagsUsers[data.type] || []), data.value]
  },
  updateTagsItem(state, data) {
    if (data.index !== undefined) {
      data.value.splice(data.index, 1)
    }
    state.tagsUsers[data.type] = data.value
  },
  reset(state) {
    Object.assign(state, defaultState())
  },
}
