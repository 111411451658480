import httpClient from "@/httpClient"

const getTags = (filters) => httpClient.get('{currentCompanySlug}/tags', { params: filters })
const createTag = (payload) => httpClient.post('{currentCompanySlug}/tags', payload)
const attachTag = (payload) => httpClient.post(`{currentCompanySlug}/tags/${payload.tagId}/attach`, payload)
const detachTag = (payload) => httpClient({
  method: 'DELETE',
  url: `{currentCompanySlug}/tags/${payload.tagId}/detach`,
  data: payload,
})
export default {
  getTags,
  createTag,
  attachTag,
  detachTag,
}
