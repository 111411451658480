import { user, webHooks } from '@/api'
import {addChatWootScript} from '@/utils/snippets'
import { logrocketInit } from "@/utils/logrocketInit"

export default {
  getCurrentUser({ commit, state, dispatch }, company = null) {
    if (state.isLoaded) {
      return null
    }
    return user.getCurrentUser(company)
      .then(({ data }) => {
        logrocketInit(data.data)
        commit('setCurrentUser', data.data)
        commit('setLoading', true)
        // addSmartLookScript(state.currentUser)
        addChatWootScript(state.currentUser)
        dispatch('getUserTypes')
        dispatch('getPermissions')
      })
  },
  saveUserAvatar({ commit }, payload) {
    return user.saveUserAvatar(payload).then(({ data }) => {
      commit('setCurrentUserAvatar', data.data.avatar)
    })
  },
  getUserTypes({ commit }) {
    return user.getUserTypes()
      .then(({ data }) => {
        commit('setRoles', data.data)
      })
  },

  getConfig: ({ commit }, company) => webHooks.appConfig(company)
    .then(({ data }) => {
      commit('setConfig', data.data.feature)
      commit('setIsConfigLoaded', true)
      sessionStorage.setItem('isDisplayOrders', 'false')
    }),

  getPermissions({ commit }) {
    return user.getUserPermissions()
      .then(({ data }) => {
        commit('setPermissions', data.data)
      })
  },
}
