import { tags } from '@/api'

export default {
  getTags({ commit }) {
    commit('preloader', true)
    return tags.getTags('')
      .then(({ data }) => {
        commit('setTags', data.data)
      }).catch((e) => {
        commit('preloader', false)
        throw e
      }).finally(() => {
        commit('preloader', false)
      })
  },
  setTags({ dispatch }, payload) {
    dispatch('setTags', payload)
  },
  setTypeTags({ commit }, payload) {
    commit('updateTagsItem', payload)
  },
  createTags({ commit }, payload) {
    commit('preloader', true)
    return tags.createTag({ name: payload.name, users: [payload.typeId] })
      .then(({ data }) => {
        commit('setTag', data.data)
        commit('setTagItem', { type: payload.typeId, value: data.data })
        commit('preloader', false)
      }).catch((e) => {
        commit('preloader', false)
        throw e
      }).finally(() => {
        commit('preloader', false)
      })
  },
  attachTags({ commit }, payload) {
    commit('preloader', true)
    return tags.attachTag({ tagId: payload.tagId, users: [payload.typeId] })
      .then(({ data }) => {
        commit('setTagItem', { type: payload.typeId, value: data.data })
        commit('preloader', false)
      }).catch((e) => {
        commit('preloader', false)
        throw e
      }).finally(() => {
        commit('preloader', false)
      })
  },
  removeTag({ commit }, payload) {
    commit('preloader', true)

    return tags.detachTag({ tagId: payload.tagId, users: [payload.typeId] })
      .then(() => {
        commit('updateTagsItem', { type: payload.typeId, value: payload.value, index: payload.index })
        commit('preloader', false)
      }).catch((e) => {
        commit('preloader', false)
        throw e
      }).finally(() => {
        commit('preloader', false)
      })
  },
}
