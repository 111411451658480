import httpClient from "@/httpClient"

let getRecordsAbortController = new AbortController()

const getRecords = (filters, dropPrevious = false) => {
  if (dropPrevious) {
    getRecordsAbortController?.abort()
    getRecordsAbortController = new AbortController()
  }

  return httpClient.post(
    '{currentCompanySlug}/quotes/all',
    filters,
    {
      signal: getRecordsAbortController.signal,
    },
  )
}


const exportRecords = (filters) => httpClient.post(
  '{currentCompanySlug}/quotes/export',
  filters,
)

const createRecord = (payload) => httpClient({
  method: 'POST',
  url: '{currentCompanySlug}/quotes',
  data: payload,
  headers: {
    notification: 'silent',
  },
  hideValidationSections: true,
})

const editRecord = ({ id, payload }, company = '{currentCompanySlug}') => httpClient.patch(`${company}/quotes/${id}`, payload, {
  validationRecordId: id,
})

const deleteRecord = ({ id }) => httpClient.delete(`{currentCompanySlug}/quotes/${id}`)

const getRecord = (id, params) => httpClient.get(`{currentCompanySlug}/quotes/${id}`, { params })

export const readRecordStatuses = (company = '{currentCompanySlug}') => httpClient.get(`${company}/quotes/statuses`)

const getRecordActivityStream = (id, params) => httpClient.get(`{currentCompanySlug}/quotes/${id}/activity-stream`, { params })

const getRecordFiles = ({ id, group }) => httpClient.get(`{currentCompanySlug}/quotes/${id}/file-attachment`, { params: { group } })

const getRecordFileGroups = (id) => httpClient.get(`{currentCompanySlug}/quotes/${id}/files-groups`)

const createMessage = ({ id, payload }) => httpClient({
  method: 'POST',
  url: `{currentCompanySlug}/quotes/${id}/activity-stream/messages`,
  data: payload,
  headers: { 'Content-Type': 'multipart/form-data' },
})

const updateMessage = ({ id, uuid, data }) => httpClient.put(
    `{currentCompanySlug}/quotes/${id}/activity-stream/messages/${uuid}`,
  {
    ...data,
  }
)

const updateRecordStatus = ({ id, status, fields }) => httpClient.patch(`{currentCompanySlug}/quotes/${id}`, { status, fields }, {
  validationRecordId: id,
})

const isExist = ({ name }) => httpClient.get('{currentCompanySlug}/quotes/exist', { params: { name } })

const getFiltersStructure = () => httpClient.get('{currentCompanySlug}/quotes/filters')

const addCollaborators = ({ id, accountIds = [] }) => httpClient({
  method: 'POST',
  url: `{currentCompanySlug}/records/${id}/collaborators/add`,
  data: { accountIds },
  headers: { 'Content-Type': 'application/json', notification: 'silent' },
})

const removeCollaborators = ({ id, accountIds }) =>
  httpClient.post(`{currentCompanySlug}/records/${id}/collaborators/remove`,{ accountIds } )

const moveChildRecord = ({ fromRecordId, objectId, toRecordId }) => httpClient.post(
  `{currentCompanySlug}/quotes/${fromRecordId}/customFields/objects/${objectId}/move`,
  { recordId: toRecordId },
)

const deleteObjectChild = ({ objectId, fieldId }) => httpClient.delete(
  `{currentCompanySlug}/quotes/customFields/${objectId}/objects/${fieldId}`,
)

const editObjectChild = ({ objectId, fieldId, data }) => httpClient.put(
  `{currentCompanySlug}/quotes/customFields/${objectId}/objects/${fieldId}`,
  {
    data,
  },
)

const createObjectChilds = ({ customFieldSlug, recordId, data }) => httpClient.post(
  `{currentCompanySlug}/quotes/${recordId}/customFields/${customFieldSlug}/mass-create`,
  {
    data,
  },
)

const getObjectChildren = ({ objectId }) => httpClient.get(
  `{currentCompanySlug}/quotes/customFields/${objectId}/objects`,
)

const getObjectChildrenMeta = ({ objectId }) => httpClient.get(
  `{currentCompanySlug}/quotes/customFields/${objectId}/objects/meta`,
)

const patchObjectField = ({ objectId, data, recordId }) => httpClient.put(
  `{currentCompanySlug}/quotes/customFields/${objectId}/objects`,
  {
    ...data,
  },
  {
    validationRecordId: recordId || null,
  },
)

const patchObjectFieldDryRun = ({ objectId, data }) => httpClient.put(
  `{currentCompanySlug}/quotes/customFields/${objectId}/objects?dry-run`,
  {
    data,
  },
  {
    ignoreGlobalNotify: true,
  },
)

const templateDocumentsList = () => httpClient.get(
  '{currentCompanySlug}/supplierCompany/template-documents',
)

const getPersistentColumns = () => httpClient.get(
  '{currentCompanySlug}/quotes/persistent-columns',
)

const savePersistentColumns = (columns) => httpClient.post(
  '{currentCompanySlug}/quotes/persistent-columns',
  {
    columns,
  },
)

const deleteFileFromRecord = (payload) => httpClient.delete(
  `{currentCompanySlug}/quotes/${payload.recordId}/file-attachment/${payload.fileId}`,
)

export const loadRecordCustomFieldsConfiguration = (forceCompany = null, params = {}) => httpClient.get(
  `${forceCompany || '{currentCompanySlug}'}/records/customFields/configurations`,
  {
    params,
  },
)

const loadRecordSections = () => httpClient.get(
  '{currentCompanySlug}/records/customFields/sections',
)

const createStatus = (payload) => httpClient.post('{currentCompanySlug}/quotes/statuses', payload)

const changeStatusesOrder = (payload) => httpClient.post('{currentCompanySlug}/quotes/statuses/reorder', payload)

const deleteStatus = ({ statusId, params }) => httpClient.delete(
  `{currentCompanySlug}/quotes/statuses/${statusId}`,
  {
    params,
  },
)

const updateStatus = (payload) => httpClient.put(`{currentCompanySlug}/quotes/statuses/${payload.id}`, payload)

const loadFiltersViews = () => httpClient.get('{currentCompanySlug}/filter-views/quotes')
export const loadFiltersViewsCounts = (ids, forceCompany = null) => httpClient.get(
  `${forceCompany || '{currentCompanySlug}'}/filter-views/quotes/counts`,
  {
    params: { ids },
  },
)

const createFiltersView = (payload) => httpClient.post('{currentCompanySlug}/filter-views/quotes', payload)

const reorderFiltersView = (payload) => httpClient.post('{currentCompanySlug}/filter-views/quotes/reorder', payload)

const deleteFiltersView = (filterViewId) => httpClient.delete(`{currentCompanySlug}/filter-views/quotes/${filterViewId}`)

const updateFiltersView = (payload) => httpClient.patch(
  `{currentCompanySlug}/filter-views/quotes/${payload.filterViewId}`, payload.data,
)

const updateFiltersViewHidden = (payload) => httpClient.post('{currentCompanySlug}/filter-views/quotes/hide', { data: payload })

const updateFavoriteView = (payload) => httpClient.post('{currentCompanySlug}/filter-views/quotes/favourite', { data: payload })

const getRecordCollaborators = (payload) => httpClient.get(`{currentCompanySlug}/records/${payload.recordId}/collaborators`)

const signTemplate = ({ orderId, templateId, payload }) => httpClient.post(
  `{currentCompanySlug}/quotes/${orderId}/template-document/${templateId}/e-sign`,
  payload,
)

export const loadCustomFieldChangeLog = ({ recordId, customFieldSlug }, page) => {
  return httpClient.get(`{currentCompanySlug}/records/${recordId}/change-log/${customFieldSlug}`, {
    params: {
      page,
    },
  })
}

export default {
  getRecords,
  createRecord,
  getRecord,
  deleteRecord,
  getRecordActivityStream,
  getRecordFiles,
  getRecordFileGroups,
  createMessage,
  updateMessage,
  readRecordStatuses,
  updateRecordStatus,
  isExist,
  addCollaborators,
  removeCollaborators,
  moveChildRecord,
  editRecord,
  exportRecords,
  getFiltersStructure,
  deleteObjectChild,
  editObjectChild,
  createObjectChilds,
  getObjectChildren,
  getObjectChildrenMeta,
  patchObjectField,
  templateDocumentsList,
  getPersistentColumns,
  savePersistentColumns,
  deleteFileFromRecord,
  loadRecordCustomFieldsConfiguration,
  createStatus,
  changeStatusesOrder,
  deleteStatus,
  updateStatus,
  loadFiltersViews,
  createFiltersView,
  deleteFiltersView,
  updateFiltersView,
  reorderFiltersView,
  updateFiltersViewHidden,
  updateFavoriteView,
  getRecordCollaborators,
  patchObjectFieldDryRun,
  loadRecordSections,
  signTemplate,
  loadFiltersViewsCounts,
}
