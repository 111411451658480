import httpClient from "@/httpClient"

const getAdminCustomFieldsConfiguration = (params) => httpClient.get(
  '/{currentCompanySlug}/records/customFields/configurations', { params },
)

const getAdminSectionsList = () => httpClient.get(
  '/{currentCompanySlug}/records/customFields/sections',
)

const createSection = (payload) => httpClient.post(
  '/{currentCompanySlug}/records/customFields/sections',
  payload,
)

const updateSection = (payload) => httpClient.put(
  `/{currentCompanySlug}/records/customFields/sections/${payload.slug}`,
  payload,
)

const reorderSections = (payload) => httpClient.post(
  '/{currentCompanySlug}/records/customFields/sections/reorder',
  { data: payload },
)

const createAdminSectionField = (payload) => httpClient.post(
  '{currentCompanySlug}/records/customFields/configurations', payload,
)

const updateAdminSectionField = (field) => httpClient.put(
  `{currentCompanySlug}/records/customFields/configurations/${field.slug}`,
  field,
  {
    headers: {
      notification: 'silent',
    },
  },
)

const updateCustomFieldsPosition = (payload) => httpClient.post(
  '{currentCompanySlug}/records/customFields/configurations/reorder',
  payload,
)

const updateCustomFieldsObjectChildPosition = (customFieldConfigurationSlug, payload) => httpClient.post(
  `{currentCompanySlug}/records/customFields/configurations/${customFieldConfigurationSlug}/objectConfiguration/reorder`,
  payload,
)

const importRecordInsert = (file) => httpClient({
  method: 'POST',
  url: '{currentCompanySlug}/quotes/import/insert',
  data: file,
  headers: { 'Content-Type': 'multipart/form-data' },
})

const importRecordUpdate = (file) => httpClient({
  method: 'POST',
  url: '{currentCompanySlug}/quotes/import/update',
  data: file,
  headers: { 'Content-Type': 'multipart/form-data' },
})

const importRecordUpsert = (file) => httpClient({
  method: 'POST',
  url: '{currentCompanySlug}/quotes/import/upsert',
  data: file,
  headers: { 'Content-Type': 'multipart/form-data' },
})

const importChildRecordInsert = (file) => httpClient({
  method: 'POST',
  url: '{currentCompanySlug}/custom-field-objects/import/insert',
  data: file,
  headers: { 'Content-Type': 'multipart/form-data' },
})

const importChildRecordUpdate = (file) => httpClient({
  method: 'POST',
  url: '{currentCompanySlug}/custom-field-objects/import/update',
  data: file,
  headers: { 'Content-Type': 'multipart/form-data' },
})

const importChildRecordUpsert = (file) => httpClient({
  method: 'POST',
  url: '{currentCompanySlug}/custom-field-objects/import/upsert',
  data: file,
  headers: { 'Content-Type': 'multipart/form-data' },
})

const importRecordTemplate = (payload) => httpClient({
  method: 'GET',
  url: '{currentCompanySlug}/quotes/import/template',
  params: payload,
  responseType: 'blob',
})

const importChildRecordTemplate = (payload) => httpClient({
  method: 'GET',
  url: '{currentCompanySlug}/custom-field-objects/import/template',
  params: payload,
  responseType: 'blob',
})

// file templates
const getFileTemplates = () => httpClient.get('{currentCompanySlug}/supplierCompany/template-documents')

const updateTemplateFile = (payload) => httpClient({
  method: 'POST',
  url: `{currentCompanySlug}/supplierCompany/template-documents/${payload.id}/file`,
  data: payload.file,
  headers: {
    'Content-Type': 'multipart/form-data',
  },
})

const updateFileTemplate = (payload) => httpClient.patch(
  `{currentCompanySlug}/supplierCompany/template-documents/${payload.id}`,
  payload.data,
)

const changeFileTemplatesOrder = (payload) => httpClient.post('{currentCompanySlug}/supplierCompany/template-documents/reorder', payload)

const deleteFileTemplate = (id) => httpClient.delete(
  `{currentCompanySlug}/supplierCompany/template-documents/${id}`,
)

const createTemplateFile = (payload) => httpClient({
  method: 'POST',
  url: '{currentCompanySlug}/supplierCompany/template-documents',
  data: payload,
  headers: { 'Content-Type': 'multipart/form-data' },
})

const loadDocumentKeyspases = () => httpClient.get('{currentCompanySlug}/supplierCompany/template-documents/keyspaces')

const loadFunctionsConfigurations = () => httpClient.get('{currentCompanySlug}/quotes/customFields/functions')

const loadLinkedCompanies = (accountId, tenantId) => httpClient.get(`{currentCompanySlug}/accounts/${accountId}/companies?fromTenant=${tenantId}`)
const loadLinkedCompaniesCustomFields = (companySlug) => httpClient.get(`${companySlug}/records/customFields/configurations`)

export default {
  getAdminCustomFieldsConfiguration,
  getAdminSectionsList,
  createSection,
  updateSection,
  reorderSections,
  importRecordInsert,
  importRecordUpdate,
  importRecordUpsert,
  importChildRecordInsert,
  importChildRecordUpdate,
  importChildRecordUpsert,
  importRecordTemplate,
  importChildRecordTemplate,
  getFileTemplates,
  updateTemplateFile,
  updateFileTemplate,
  changeFileTemplatesOrder,
  createTemplateFile,
  deleteFileTemplate,
  loadDocumentKeyspases,
  createAdminSectionField,
  updateAdminSectionField,
  updateCustomFieldsPosition,
  updateCustomFieldsObjectChildPosition,
  loadFunctionsConfigurations,
  loadLinkedCompanies,
  loadLinkedCompaniesCustomFields,
}
