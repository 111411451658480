// import smartlookClient from 'smartlook-client'
import CryptoJS from 'crypto-js'
import getHashedState from "@/utils/getHashedState"
const chatWootToken = import.meta.env.VITE_APP_CHATWOOT_TOKEN
const chatWootScript = `window.chatwootSettings = {
  hideMessageBubble: false,
};
(function(d,t) {
  var BASE_URL="https://app.chatwoot.com";
  var g=d.createElement(t),s=d.getElementsByTagName(t)[0];
  g.src=BASE_URL+"/packs/js/sdk.js?t=${new Date().getTime()}";
  s.parentNode.insertBefore(g,s);
  g.onload=function(){
    window.chatwootSDK.run({
      websiteToken: '${chatWootToken}',
      baseUrl: BASE_URL
    })
  }
})(document,"script");`
const CHATWOOT_SCRIPT_ID = 'chatWootScript'
// const APP_KEY = '279f5517c168a751f9f8b44401d625307f9b07df'

const placeScript = (scriptText, id) => {
  if (document.getElementById(id)) {
    removeChatWootScript()
  }
  const script = document.createElement('script')
  const inlineScript = document.createTextNode(scriptText)

  script.setAttribute('type', 'text/javascript')
  script.setAttribute('language', 'javascript')
  script.setAttribute('id', id)

  script.appendChild(inlineScript)
  const head = document.getElementsByTagName('head')
  if (head.length) {
    head[0].appendChild(script)
  }
}

// export const addSmartLookScript = (user) => {
//   if (import.meta.env.VITE_APP_ENV === 'production' && user) {
//     smartlookClient.init(APP_KEY)
//     smartlookClient.identify(user.id, {
//       displayName: user.fullName,
//       email: user.email,
//     })
//   }
// }

// export const removeSmartLookScript = () => {
//   if (import.meta.env.VITE_APP_ENV === 'production') {
//     smartlookClient.anonymize()
//   }
// }

function changeElementVisibility(element, style) {
  if (document.getElementsByClassName(element)?.length) {
    document.getElementsByClassName(element)[0].style = `display: ${style}`
  }
}

export const addChatWootScript = async (user) => {
  // In the future, we will likely turn this back on for
  // suppliers only, so leaving the condition commented.
  // if (this.isStandart)
  if (user && window.$chatwoot?.user?.email !== user.email) {
    // eslint-disable-next-line no-console
    await placeScript(chatWootScript, CHATWOOT_SCRIPT_ID)
    const onSetChatwoot = () => {
      const userIdentifier = import.meta.env.VITE_APP_ENV === 'production'
        ? String(user.id)
        : getHashedState({ id: user.id }, import.meta.env.VITE_APP_ENV)

      const chatWootIdentity = import.meta.env.VITE_APP_CHATWOOT_USER_IDENTITY
      const hash = CryptoJS.HmacSHA256(userIdentifier, chatWootIdentity).toString()
      window.$chatwoot.setUser(userIdentifier, {
        id: String(user.id),
        email: String(user.email),
        name: String(`${user.firstName} ${user.lastName}`),
        identifier_hash: hash,
      })
      window.$chatwoot.setCustomAttributes({
        jobTitle: user.jobTitle,
        phone: user.phone,
      })

      const browserLocale = navigator.language || navigator.userLanguage

      // Extract the ISO 639 language code
      const iso639LanguageCode = browserLocale.split('-')[0]
      const supportedLanguages = [
        'en',
        'ar',
        'nl',
        'fr',
        'de',
        'hi',
        'it',
        'ja',
        'ko',
        'pt',
        'ru',
        'zh',
        'es',
        'ml',
        'ca',
        'el',
        'pt_BR',
        'ro',
        'ta',
        'fa',
        'zh_TW',
        'vi',
        'da',
        'tr',
        'cs',
        'fi',
        'id',
        'sv',
        'hu',
        'no',
        'zh_CN',
        'pl',
      ]
      window.$chatwoot.setLocale(
        supportedLanguages.includes(iso639LanguageCode)
          ? iso639LanguageCode
          : 'en',
      )
    }
    if (window.$chatwoot) {
      window.$chatwoot.reset()
      changeElementVisibility('woot-widget-holder', 'block')
      changeElementVisibility('woot--bubble-holder', 'block')
    }
    window.addEventListener('chatwoot:ready', function handleChatwoot() {
      onSetChatwoot()
      window.removeEventListener('chatwoot:ready', handleChatwoot, false)
    })
  }
}

export const removeChatWootScript = () => {
  const existScript = document.getElementById(CHATWOOT_SCRIPT_ID)
  if (existScript) {
    document.getElementById(CHATWOOT_SCRIPT_ID).remove()
    changeElementVisibility('woot-widget-holder', 'none')
    changeElementVisibility('woot--bubble-holder', 'none')
    if (window?.$chatwoot?.user) {
      delete window.$chatwoot.user
    }
  }
}
