import auth from '@/middleware/auth'
import guest from '@/middleware/guest'
import logout from '@/middleware/logout'
import internalAdmin from '@/middleware/internalAdmin'
import godUser from '@/middleware/godUser'
import AuthLayout from '@/layouts/AuthLayout.vue'
import dayjs from "dayjs"

const routes = [
  {
    path: '/test-page',
    name: 'test-page',
    component: () => import('@/views/TestPage.vue'),
  },
  {
    path: '/',
    component: AuthLayout,
    children: [
      {
        path: '/login',
        name: 'login',
        component: () => import('@/views/Auth/Login.vue'),
      },
      {
        path: '/password-forgot',
        name: 'password-forgot',
        component: () => import('@/views/Auth/ForgotPassword.vue'),
        meta: {
          middleware: guest,
        },
      },
      {
        path: '/password-reset',
        name: 'password-reset',
        component: () => import('@/views/Auth/ResetPassword.vue'),
        meta: {
          middleware: guest,
        },
      },
      {
        path: '/password-create',
        name: 'password-create',
        props: {
          isCreate: true,
        },
        component: () => import('@/views/Auth/ResetPassword.vue'),
        meta: {
          middleware: guest,
        },
      },
      {
        path: '/create-account',
        name: 'create-account',
        beforeEnter(to, from, next) {
          if (to.query.company) {
            next({ name: 'company-create-account', params: {company: to.query.company}, query: to.query })
          } else {
            next({ name: 'login' })
          }
        },
      },
      {
        path: '/:company',
        component: () => import('@/views/Auth/CompanyAuth.vue'),
        redirect: { name: 'company-login' },
        children: [
          {
            path: 'login',
            name: 'company-login',
            component: () => import('@/views/Auth/Login.vue'),
          },
          {
            path: 'password-forgot',
            name: 'company-password-forgot',
            component: () => import('@/views/Auth/ForgotPassword.vue'),
            meta: {
              middleware: guest,
            },
          },
          {
            path: 'password-reset',
            name: 'company-password-reset',
            component: () => import('@/views/Auth/ResetPassword.vue'),
            meta: {
              middleware: guest,
            },
          },
          {
            path: 'create-account',
            name: 'company-create-account',
            component: () => import('@/views/Auth/CreateAccount.vue'),
            meta: {
              middleware: guest,
            },
          },
        ],
      },
    ],
    beforeEnter() {
      const metaTheme = document.getElementById('theme-color-id')
      if (metaTheme) {
        return
      }
      const meta = document.createElement('meta', {
        id: 'theme-color-id',
        name: "theme-color",
        content: "#f3f4fb",
      })
      meta.id = 'theme-color-id'
      meta.name = 'theme-color'
      meta.content = '#f3f4fb'
      document.head.appendChild(meta)
    },
    beforeLeave() {
      const metaTheme = document.getElementById('theme-color-id')
      document.head.removeChild(metaTheme)
    },
    redirect: { name: 'login' },
  },
  {
    path: '/:company/v-form/:form_id',
    name: 'public-v-form',
    component: () => import('@/views/VForm.vue'),
  },
  {
    path: '/logout',
    name: 'logout',
    meta: {
      middleware: logout,
    },
  },
  {
    path: '/undefined/*',
    redirect: { name: 'god-user' },
  },
  {
    path: '/',
    component: () => import('@/layouts/HomeLayout.vue'),
    middleware: auth,
    children: [
      {
        path: 'home',
        name: 'home',
        component: () => import('@/views/Home/index.vue'),
        meta: {
          middleware: auth,
        },
      },
    ],
  },
  {
    path: '/:company',
    component: () => import('@/layouts/Default.vue'),
    redirect: { name: 'pre-orders' },
    middleware: auth,
    beforeEnter(to, from, next) {
      try {
        const recentWorkflows = JSON.parse(localStorage.getItem('recentWorkflows')) || []
        const currentDate = dayjs().toISOString()
        const existingIndex = recentWorkflows.findIndex(item => item.slug === to.params.company)
        if (existingIndex !== -1) {
          recentWorkflows[existingIndex].date = currentDate
        } else {
          recentWorkflows.push({ slug: to.params.company, date: currentDate })
        }
        localStorage.setItem('recentWorkflows', JSON.stringify(recentWorkflows))
      } catch {
        localStorage.setItem('recentWorkflows', JSON.stringify([]))
      }
      next()
    },
    children: [
      {
        path: 'god',
        name: 'god-user',
        component: () => import('@/views/God/GodUser.vue'),
        meta: {
          middleware: godUser,
        },
      },
      {
        path: 'create-supplier',
        name: 'god-create-supplier',
        component: () => import('@/views/God/CreateSupplier.vue'),
        meta: {
          middleware: godUser,
        },
      },
      {
        path: 'dashboard',
        name: 'dashboard',
        component: () => import('@/views/Dashboard/index.vue'),
        meta: {
          middleware: auth,
          pageTitle: 'Dashboard',
        },
      },
      {
        path: 'products/:productSlug',
        component: () => import('@/views/Products/ProductsViews.vue'),
        children: [
          {
            path: '',
            component: () => import('@/views/Products/index.vue'),
            name: 'products',
            meta: {
              middleware: auth,
            },
          },
          {
            path: 'views/:view_id(\\d+)',
            name: 'products-view',
            component: () => import('@/views/Products/index.vue'),
            meta: {
              middleware: auth,
            },
          },
          {
            path: 'views/settings',
            name: 'products-view-settings',
            component: () => import('@/views/Products/Settings.vue'),
            meta: {
              middleware: auth,
            },
          },
        ],
      },
      {
        path: 'pre-orders',
        component: () => import('@/views/Records/RecordsViews.vue'),
        meta: {
          middleware: auth,
        },
        children: [
          {
            path: '',
            name: 'pre-orders',
            component: () => import('@/views/Records/index.vue'),
            props: { isPersist: true },
            meta: {
              middleware: auth,
              pageTitle: 'Records',
              customMobileHeader: true,
            },
          },
          {
            path: 'views/settings',
            name: 'pre-orders-saved-view-settings',
            component: () => import('@/views/Records/Settings.vue'),
            meta: {
              middleware: auth,
              pageTitle: 'Customize Tabs',
            },
          },
          {
            path: 'views/:view_id(\\d+)',
            name: 'pre-orders-saved-view',
            component: () => import('@/views/Records/index.vue'),
            props: { isPersist: false },
            meta: {
              middleware: auth,
              customMobileHeader: true,
            },
          },
          {
            path: 'views',
            redirect: { name: 'pre-orders' },
          },
        ],
      },
      {
        path: 'resources',
        name: 'resources',
        component: () => import('@/views/Resources/index.vue'),
        meta: {
          middleware: auth,
          pageTitle: 'Resources',
          pageDescription: 'Manage your organization’s resources here.',
        },
      },
      {
        path: 'resources/:resourceId(\\d+)',
        name: 'resource',
        component: () => import('@/views/Resources/index.vue'),
        meta: {
          middleware: auth,
          pageTitle: 'Resources',
          pageDescription: 'Manage your organization’s resources here.',
        },
      },
      {
        path: 'pre-orders/:order_id(\\d+)',
        name: 'pre-order',
        component: () => import('@/views/Record/index.vue'),
        meta: {
          middleware: auth,
        },
      },
      {
        path: 'admin',
        component: () => import('@/views/Admin/index.vue'),
        meta: {
          middleware: [internalAdmin],
        },
        children: [
          {
            path: '',
            name: 'admin-panel',
            component: () => import('@/views/Admin/views/index.vue'),
            meta: {
              pageTitle: 'Admin',
            },
          },
          {
            path: 'fields-and-sections',
            name: 'admin-fields-and-sections',
            component: () => import('@/views/Admin/views/FieldsAndSections.vue'),
            meta: {
              pageTitle: 'Edit Fields and Sections',
              pageDescription: 'You’re currently making System Wide Changes. Changes made here will impact ALL Records',
            },
          },
          {
            path: 'import-or-update-records',
            name: 'admin-import-or-update-records',
            component: () => import('@/views/Admin/views/ImportUpdateRecords.vue'),
          },
          {
            path: 'file-types',
            name: 'admin-file-types',
            component: () => import('@/views/Admin/views/FileTypes.vue'),
            meta: {
              pageTitle: 'File Types',
              pageDescription: 'Create or Update File Types.',
            },
          },
          {
            path: 'file-templates',
            name: 'admin-file-templates',
            component: () => import('@/views/Admin/views/FileTemplates.vue'),
            meta: {
              pageTitle: 'File Templates',
              pageDescription: `
                Create pre-filled documents like quotes, BOLs, or contracts using Vsimple data. Click
                <a
                  href="https://docs.vsimple.com/hc/vsimple/en/categories/file-templates"
                  rel="noreferrer noopener"
                  target="_blank"
                >here</a>
                or message us to learn more!`,
            },
          },
          {
            path: 'statuses',
            name: 'admin-statuses',
            component: () => import('@/views/Admin/views/Statuses.vue'),
            meta: {
              pageTitle: 'Statuses',
              pageDescription: 'Create or Update Record Statuses',
            },
          },
          {
            path: 'company-info',
            name: 'admin-company',
            component: () => import('@/views/Admin/views/CompanyInfo.vue'),
            meta: {
              pageTitle: 'Company Info',
              pageDescription: 'Manage your company’s branding here',
            },
          },
          {
            path: 'automations',
            component: () => import('@/views/Admin/views/AutomationsAndValidations/AutomationAndValidationLayout.vue'),
            children: [
              {
                path: '',
                name: 'admin-automations',
                component: () => import('@/views/Admin/views/AutomationsAndValidations/index.vue'),
                props: {
                  type: 'automation',
                },
                meta: {
                  pageTitle: 'Automations',
                  pageDescription: 'Manage your Vsimple automations here.',
                },
              },
              {
                path: 'new',
                name: 'admin-automation-new',
                component: () => import('@/views/Admin/views/AutomationsAndValidations/AutomationAndValidationForm.vue'),
                props: {
                  type: 'automation',
                },
                meta: {
                  pageTitle: 'New Automation',
                },
              },
              {
                path: ':automation_id(\\d+)',
                name: 'admin-automation',
                component: () => import('@/views/Admin/views/AutomationsAndValidations/AutomationAndValidationForm.vue'),
                props: {
                  type: 'automation',
                },
                meta: {
                  pageTitle: 'Edit Automation',
                },
              },
            ],
          },
          {
            path: 'validations',
            component: () => import('@/views/Admin/views/AutomationsAndValidations/AutomationAndValidationLayout.vue'),
            children: [
              {
                path: '',
                name: 'admin-validations',
                component: () => import('@/views/Admin/views/AutomationsAndValidations/index.vue'),
                props: {
                  type: 'validation',
                },
                meta: {
                  pageTitle: 'Validations',
                  pageDescription: 'Prevent unwanted behavior',
                },
              },
              {
                path: 'new',
                name: 'admin-validation-new',
                component: () => import('@/views/Admin/views/AutomationsAndValidations/AutomationAndValidationForm.vue'),
                props: {
                  type: 'validation',
                },
                meta: {
                  pageTitle: 'New Validation',
                },
              },
              {
                path: ':validation_id(\\d+)',
                name: 'admin-validation',
                component: () => import('@/views/Admin/views/AutomationsAndValidations/AutomationAndValidationForm.vue'),
                props: {
                  type: 'validation',
                },
                meta: {
                  pageTitle: 'Edit Validation',
                },
              },
            ],
          },
          {
            path: 'record-types',
            name: 'admin-record-types',
            component: () => import('@/views/Admin/views/RecordTypes.vue'),
            meta: {
              pageTitle: 'Record Types',
              pageDescription: 'Record Types are used to hide irrelevant sections from your Vsimple records',
            },
          },
          {
            path: 'integrations',
            name: 'admin-integrations',
            component: () => import('@/views/Admin/views/Integrations.vue'),
            meta: {
              pageTitle: 'Integrations',
              pageDescription: 'View all the organizations integrations to 3rd party systems',
            },
          },
          {
            path: 'integrations/:integration_id/edit',
            name: 'admin-integration-field-mapping',
            component: () => import('@/views/Admin/views/IntegrationFieldMapping.vue'),
            meta: {
              pageTitle: 'Edit integration config',
              pageDescription: `Here you can manage integration field mapping config.
                <a
                  target="_blank"
                  rel="noreferrer noopener"
                  href="https://chatwoot.help/hc/vsimple/articles/1707739701-integration-admin-page"
                >Learn more here!</a>`,
            },
          },
          {
            path: 'v-forms',
            name: 'admin-v-forms',
            component: () => import('@/views/Admin/views/VForms/VForms.vue'),
            meta: {
              pageTitle: 'vForms',
              pageDescription: 'Create and edit forms',
            },
          },
          {
            path: 'v-forms/builder/:form_id?',
            name: 'admin-v-form-builder',
            component: () => import('@/views/Admin/views/VForms/VFormBuilder.vue'),
            meta: {
              pageTitle: 'vForms',
              pageDescription: 'Create or edit form',
            },
          },
          {
            path: '*',
            redirect: { name: 'admin-panel' },
          },
        ],
      },
      {
        path: 'users',
        name: 'users',
        component: () => import('@/views/Users/index.vue'),
        meta: {
          middleware: auth,
          pageTitle: 'User Management',
        },
      },
      {
        path: 'users/:userId(\\d+)/settings',
        name: 'user-settings',
        props: { isEdit: true },
        component: () => import('@/views/UserSettings/index.vue'),
        meta: {
          middleware: auth,
          pageTitle: 'User Settings',
          pageDescription: 'Change user’s settings',
        },
      },
      {
        path: 'users/create',
        name: 'user-create',
        component: () => import('@/views/UserSettings/index.vue'),
        meta: {
          middleware: auth,
          pageTitle: 'User Settings',
          pageDescription: 'Create user’s settings',
        },
      },
    ],
  },
  {
    path: '/:company?/*',
    component: () => import('@/layouts/Default.vue'),
    children: [
      {
        path: '',
        component: () => import('@/views/NotFound.vue'),
        name: 'not-found',
      },
    ],
    meta: {
      middleware: auth,
    },
  },
]

const unavailableRoutes = [
  {
    path: '/:catchAll(.*)',
    component: () => import('@/views/UnderConstruction.vue'),
  },
]

export default import.meta.env.VITE_APP_UNAVAILABLE === 'true' ? unavailableRoutes : routes
